/*
loads in an image and creates a tile with soft corners
*/
import React from "react"
import cardSyles from "./card.module.css"
import mortage from "../images/house.jpg"
import Image from "../components/image"
function Showcase(props){
    return (

    <div className={cardSyles.tileOuter}>
      {/* <img className={cardSyles.image} src={mortage} alt="mortgage"/> */}
      <Image className={cardSyles.image} alt={ props.filename } filename={ props.filename }/>
      <span className={cardSyles.tileTitle}>
        { props.title }
      </span>
      <span className={cardSyles.tileBody}>
        { props.body}
      </span>
    </div>


    )
  }
  
  
export default Showcase