import React from "react"
import waveFooter from "../../images/wave_footer.svg"
import footerStyle from "./footer.module.css"


function WaveFooter(){
  return (
    <img className={footerStyle.footer} src={waveFooter} alt="wave"/>
  )
}

export default WaveFooter
