import React from "react"
import wave from "../../images/wave.webp"
import waveStyles from "./wave.module.css"
import piggy from "../../images/piggy-bank.svg"

function Wave(){
  return (
  <div className={waveStyles.wave}>
    <span className={waveStyles.intro}>
      <h3>The UK's #1 site for Finance Advice</h3>
      <h6>Financial stability through uncertainty</h6>
    </span>
    {/* <img className={waveStyles.wave} src={wave} alt="wave"/> */}
    <img className={waveStyles.piggy} src={piggy} alt="piggy"/>
  </div>
  )
}


export default Wave
