import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Wave from "../components/common/wave"
import WaveFooter from "../components/common/footer"
import Container from "../components/container"
import construction from "../images/construction.svg"
import Card from "../components/card"
const IndexPage = () => (
  <>
  <SEO title="financeadviceuk" />
  <Layout/>
  <Wave/>
  <Container>
    <p>This site is still under construction</p>
    <img src={construction} alt="construction" width="100"/>

  </Container>
  <Container>
    <Card filename="house.jpg" title="Mortgage" body="We can help you get the best possible mortgage with our industry breaking algorithms."/>
    <Card filename="businessman.jpg" title="Loans" body="Our loans are the most competitive around"/>
    <Card filename="fiver.jpg" title="Invest" body="Free advice for the best way to keep hold of your money and prepare for the future"/>
  </Container>

  <WaveFooter/>
  </>
)

export default IndexPage
